import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

function AboutUs(props) {
  return (
    <>
      <SEO title="awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../images/sliders/coach.jpg")}
                alt=""
                class="first image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>About Us</h1>
                <h3>Experience New Zealand the awesome way</h3>
              </div>
              <div class="destination-content">
                <h2 class="tour">Why Travel with us?</h2>
                <p>
                  With few places in the world remaining unexplored these days,
                  it's getting harder to find those intrepid, deeply rewarding
                  travel experiences. awesomeNZ® offers a collection of some of
                  New Zealand's most interactive, culturally engaging and
                  thrilling experiences.
                </p>
                <p>
                  Travel in comfort in our purpose-built coaches, equipped with
                  air-conditioning and free WiFi*.
                </p>
                <p>All tours depart daily.</p>
                <p class="smaller">
                  *WiFi not available on Cape Reinga day tour.
                </p>
                <p>&nbsp;</p>
                <h2 class="tour">Passionate Local Guides</h2>
                <p>
                  Our crews are all local people with an in-depth knowledge of
                  their region. They will share with you their stories of our
                  people, history and culture and ensure you have a truly
                  awesome experience!
                </p>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default AboutUs
